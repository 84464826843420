// extracted by mini-css-extract-plugin
export var content = "styles-module--content--LA7QG";
export var desktop = "1340px";
export var downloadIcon = "styles-module--downloadIcon--gPbbZ";
export var giant = "2200px";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var links = "styles-module--links--eSkSp";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";