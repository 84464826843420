// extracted by mini-css-extract-plugin
export var activePage = "styles-module--activePage--v2kDJ";
export var categoryGroup = "styles-module--categoryGroup--kE7e9";
export var categoryTitle = "styles-module--categoryTitle--8gkc7";
export var desktop = "1340px";
export var disabledLink = "styles-module--disabledLink--trnwS";
export var giant = "2200px";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var link = "styles-module--link--HJ2F-";
export var links = "styles-module--links--NCDdA";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";