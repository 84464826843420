import React from "react";
import { DesignPrincipleBlock, SliceConfig } from "~components";
import { IDesignPrincipleBlock, ISliceConfig } from "~schemas";
import * as styles from "./styles.module.scss";

interface IProps {
  data: {
    blocks: IDesignPrincipleBlock[];
  };
}

const SliceConfigSettings: ISliceConfig = {
  slicePadding: {
    paddingTop: "none"
  }
};

const DesignPrincipleBlocks = ({ data: { blocks } }: IProps) => {
  return (
    <SliceConfig config={SliceConfigSettings}>
      {blocks.map((block, i) => (
        <DesignPrincipleBlock
          key={i}
          className={styles.block}
          index={i}
          title={block.title}
          backgroundColor={block.backgroundColor}
          principles={block.principles}
        />
      ))}
    </SliceConfig>
  );
};

export default DesignPrincipleBlocks;
