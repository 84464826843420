// Need to update 'cateogry' options in 'colorGrid.ts' to match these
type TColorCategory = `mono` | `digital/ux` | `brand` | `product`;

export interface IColorData {
  id: string;
  title: string;
  category: TColorCategory;
  hex: string;
  display: `light` | `dark`;
}

const colors: IColorData[] = [
  // Mono
  {
    id: "white",
    title: "White",
    category: "mono",
    hex: "#FFFFFF",
    display: "light"
  },
  {
    id: "black-10",
    title: "Black 10",
    category: "mono",
    hex: "#F0F0F0",
    display: "light"
  },
  {
    id: "black-20",
    title: "Black 20",
    category: "mono",
    hex: "#dddee2",
    display: "light"
  },
  {
    id: "black-40",
    title: "Black 40",
    category: "mono",
    hex: "#919191",
    display: "light"
  },
  {
    id: "black-60",
    title: "Black 60",
    category: "mono",
    hex: "#4F4F4F",
    display: "dark"
  },
  {
    id: "black-70",
    title: "Black 70",
    category: "mono",
    hex: "#323232",
    display: "dark"
  },
  {
    id: "black-90",
    title: "Black 90",
    category: "mono",
    hex: "#1E1E1E",
    display: "dark"
  },
  {
    id: "black",
    title: "Black",
    category: "mono",
    hex: "#000000",
    display: "dark"
  },
  // Digital/UX
  {
    id: "ux-error",
    title: "UX Error",
    category: "digital/ux",
    hex: "#f75757",
    display: `dark`
  },
  {
    id: "ux-success",
    title: "UX Success",
    category: "digital/ux",
    hex: "#07cb9c",
    display: `dark`
  },
  // Brand
  {
    id: "green-100",
    title: "Green 100",
    category: "brand",
    hex: "#1D2119",
    display: "dark"
  },
  {
    id: "green-75",
    title: "Green 75",
    category: "brand",
    hex: "#435B2A",
    display: "dark"
  },
  {
    id: "green-50",
    title: "Green 50",
    category: "brand",
    hex: "#A2B95E",
    display: "light"
  },
  {
    id: "green-25",
    title: "Green 25",
    category: "brand",
    hex: "#D3E493",
    display: "light"
  },
  {
    id: "mid-cream",
    title: "Mid Cream",
    category: "brand",
    hex: "#F2DFD1",
    display: "light"
  },
  {
    id: "light-cream",
    title: "Light Cream",
    category: "brand",
    hex: "#F0ECDF",
    display: "light"
  },
  // Product
  {
    id: "red-100",
    title: "Red 100",
    category: "product",
    hex: "#3A030D",
    display: "dark"
  },
  {
    id: "red-75",
    title: "Red 75",
    category: "product",
    hex: "#821E28",
    display: "dark"
  },
  {
    id: "red-50",
    title: "Red 50",
    category: "product",
    hex: "#D84C47",
    display: "dark"
  },
  {
    id: "red-25",
    title: "Red 25",
    category: "product",
    hex: "#FA8C82",
    display: "light"
  },
  {
    id: "purple-100",
    title: "Purple 100",
    category: "product",
    hex: "#32152C",
    display: "dark"
  },
  {
    id: "purple-75",
    title: "Purple 75",
    category: "product",
    hex: "#6F2861",
    display: "dark"
  },
  {
    id: "purple-50",
    title: "Purple 50",
    category: "product",
    hex: "#86477A",
    display: "dark"
  },
  {
    id: "purple-25",
    title: "Purple 25",
    category: "product",
    hex: "#C06EB1",
    display: "light"
  },
  {
    id: "blue-100",
    title: "Blue 100",
    category: "product",
    hex: "#112543",
    display: "dark"
  },
  {
    id: "blue-75",
    title: "Blue 75",
    category: "product",
    hex: "#1E436F",
    display: "dark"
  },
  {
    id: "blue-50",
    title: "Blue 50",
    category: "product",
    hex: "#28719A",
    display: "dark"
  },
  {
    id: "blue-25",
    title: "Blue 25",
    category: "product",
    hex: "#3DA2B8",
    display: "dark"
  }
];

export default colors;
