import React from "react";
import cn from "classnames";
import { Link } from "gatsby";
import * as styles from "./styles.module.scss";

interface IProps {
  disabled?: boolean;
  isActivePage?: boolean;
  title: string;
  slug: {
    current: string;
  };
  typeClass?: string;
}

const PageLink = ({
  disabled,
  isActivePage,
  title,
  slug,
  typeClass = `h1`
}: IProps) => (
  <div className={cn(typeClass, styles.container)}>
    {disabled ? (
      <div className={cn(styles.disabledLink, styles.listItem)}>{title}</div>
    ) : (
      <Link
        className={cn(styles.listItem, {
          [styles.activePage]: isActivePage
        })}
        to={`/${slug.current}`}
      >
        <div className={styles.unswiped}>{title}</div>
        <div className={styles.swiped}>{title}</div>
      </Link>
    )}
  </div>
);

export default PageLink;
