import React, { useEffect, useRef } from "react";
import { IntersectionAnimation } from "~components";
import { ISliceConfig } from "~schemas";
import cn from "classnames";
import { gsap } from "gsap";
import * as styles from "./styles.module.scss";
import { useBreakpoints } from "~hooks";

import balsamic from "./assets/balsamic.png";
import onion from "./assets/onion.png";
import peaPod from "./assets/pea-pod.png";
import stick from "./assets/stick.png";
import background1 from "./assets/background-1.png";
import background2 from "./assets/background-2.png";
import background3 from "./assets/background-3.png";
import background4 from "./assets/background-4.png";

interface IProps {
  data: {
    title: string;
    sliceConfig: ISliceConfig;
  };
}

interface IRefArrayItem {
  ref: React.RefObject<HTMLImageElement>;
  multiplier: number;
}

const Hero = ({ data: { title } }: IProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const onionRef = useRef<HTMLImageElement>(null);
  const peaRef = useRef<HTMLImageElement>(null);
  const balsamicRef = useRef<HTMLImageElement>(null);
  const stickRef = useRef<HTMLImageElement>(null);
  const bg1Ref = useRef<HTMLImageElement>(null);
  const bg2Ref = useRef<HTMLImageElement>(null);
  const bg3Ref = useRef<HTMLImageElement>(null);
  const bg4Ref = useRef<HTMLImageElement>(null);

  const imageRefs: IRefArrayItem[] = [
    {
      ref: onionRef,
      multiplier: 130
    },
    {
      ref: peaRef,
      multiplier: 70
    },
    {
      ref: balsamicRef,
      multiplier: 100
    },
    {
      ref: stickRef,
      multiplier: 80
    },
    {
      ref: bg1Ref,
      multiplier: 20
    },
    {
      ref: bg2Ref,
      multiplier: 50
    },
    {
      ref: bg3Ref,
      multiplier: 30
    },
    {
      ref: bg4Ref,
      multiplier: 40
    }
  ];

  const getOffsetPosition = (e: MouseEvent) => {
    const boundingRect = containerRef.current?.getBoundingClientRect();
    const { height = 0, width = 0 } = boundingRect || {};

    const offsetX = (e.clientX / width) * 2 - 1;
    const offsetY = (e.clientY / height) * 2 - 1;
    return { offsetX, offsetY };
  };

  const DURATION = 1;

  const handleMouseMove = (e: MouseEvent) => {
    const Y_AXIS_MULTIPLIER = 0.25;

    const { offsetX, offsetY } = getOffsetPosition(e);

    imageRefs.forEach((ref) => {
      gsap.to(ref.ref.current, {
        transform: `translate(${offsetX * ref.multiplier}px, ${
          offsetY * (ref.multiplier * Y_AXIS_MULTIPLIER)
        }px)`,
        duration: DURATION
      });
    });
  };

  const reset = () => {
    imageRefs.forEach((ref) => {
      gsap.to(ref.ref.current, {
        transform: `translate(0px, 0px)`,
        duration: DURATION
      });
    });
  };

  const {
    breakpoints: { largeTablet }
  } = useBreakpoints();

  useEffect(() => {
    if (largeTablet) {
      window.addEventListener("mousemove", handleMouseMove);
    } else {
      window.removeEventListener("mousemove", handleMouseMove);
      reset();
    }
    return () => window.removeEventListener("mousemove", handleMouseMove);
  }, [largeTablet]);

  return (
    <section className={styles.overflowWrapper} ref={containerRef}>
      <IntersectionAnimation animation="fadeGrow">
        <div className={styles.container}>
          <img
            src={background1}
            alt=""
            className={cn(styles.image, styles.bg1)}
            ref={bg1Ref}
          />

          <img
            src={background3}
            alt=""
            className={cn(styles.image, styles.bg3)}
            ref={bg3Ref}
          />
          <img
            src={background4}
            alt=""
            className={cn(styles.image, styles.bg4)}
            ref={bg4Ref}
          />
          <img
            src={balsamic}
            alt=""
            className={cn(styles.image, styles.balsamic)}
            ref={balsamicRef}
          />
          <img
            src={onion}
            alt=""
            className={cn(styles.image, styles.onion)}
            ref={onionRef}
          />
          <img
            src={peaPod}
            alt=""
            className={cn(styles.image, styles.peaPod)}
            ref={peaRef}
          />
          <img
            src={stick}
            alt=""
            className={cn(styles.image, styles.stick)}
            ref={stickRef}
          />
          <img
            src={background2}
            alt=""
            className={cn(styles.image, styles.bg2)}
            ref={bg2Ref}
          />

          <h1 className={cn(`d1`, styles.text)}>{title}</h1>
        </div>
      </IntersectionAnimation>
    </section>
  );
};

export default Hero;
