// extracted by mini-css-extract-plugin
export var background = "styles-module--background--EAJbC";
export var balsamic = "styles-module--balsamic--maDN0";
export var bg1 = "styles-module--bg1--JYx6M";
export var bg2 = "styles-module--bg2--9RLFH";
export var bg3 = "styles-module--bg3--mM0zC";
export var bg4 = "styles-module--bg4--ALvKe";
export var container = "styles-module--container--0nKjO";
export var desktop = "1340px";
export var giant = "2200px";
export var image = "styles-module--image--325+x";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var onion = "styles-module--onion--kVdHy";
export var overflowWrapper = "styles-module--overflowWrapper--Pdjwc";
export var peaPod = "styles-module--peaPod--rbwUt";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var stick = "styles-module--stick---nKjT";
export var tablet = "769px";
export var text = "styles-module--text--IYfmF";