import React from "react";
import cn from "classnames";
import { SliceConfig } from "~components";

import * as styles from "./styles.module.scss";
import { IDesignPrincipleBlock, ISliceConfig } from "~schemas";

const sliceConfigSettings: ISliceConfig = {
  slicePadding: {
    paddingTop: `small`,
    paddingBottom: `small`,
    paddingX: `regular`
  },
  textColor: {
    hex: `var(--color-green-100)`
  },
  backgroundColor: {
    hex: `var(--color-light-cream)`
  }
};

const swipedConfigSettings: ISliceConfig = {
  ...sliceConfigSettings,
  textColor: {
    hex: `var(--color-light-cream)`
  }
};

interface IProps extends IDesignPrincipleBlock {
  index: number;
  className?: string;
}

const DesignPrincipleBlock = ({
  title,
  backgroundColor,
  principles,
  className,
  index
}: IProps) => {
  const itemNumber = index + 1;

  return (
    <div className={cn(styles.container, className)}>
      <div className={styles.unswiped}>
        <SliceConfig config={sliceConfigSettings}>
          <p className={cn(styles.itemNumber, `b1`)}>{itemNumber}</p>
          <div className={styles.mainContent}>
            <h4 className={cn(`d1`, styles.title)}>{title}</h4>
          </div>
        </SliceConfig>
      </div>

      <div className={styles.swiped}>
        <SliceConfig
          config={{
            ...swipedConfigSettings,
            backgroundColor: {
              hex: backgroundColor.hex
            }
          }}
        >
          <p className={cn(styles.itemNumber, `b1`)}>{itemNumber}</p>
          <div className={styles.mainContent}>
            <h4 className={cn(`d1`, styles.title)}>{title}</h4>
            <div>
              {principles.map((principle, i) => (
                <div key={i} className="h3">
                  {principle}
                </div>
              ))}
            </div>
          </div>
        </SliceConfig>
      </div>
    </div>
  );
};

export default DesignPrincipleBlock;
