// extracted by mini-css-extract-plugin
export var activeOption = "styles-module--activeOption--FhdAj";
export var breakpointSet = "styles-module--breakpointSet--w0I3S";
export var colRightAlign = "styles-module--colRightAlign---Xfm+";
export var container = "styles-module--container--qlAal";
export var desktop = "1340px";
export var giant = "2200px";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var narrowColumn = "styles-module--narrowColumn--kH3yg";
export var row = "styles-module--row--SQ30-";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var specimenRow = "styles-module--specimenRow--Nx4Nq";
export var tablet = "769px";
export var toggleButtons = "styles-module--toggleButtons--yX-RJ";
export var wideColumn = "styles-module--wideColumn--STS0u";