// extracted by mini-css-extract-plugin
export var border = "styles-module--border--E39ec";
export var borderVisible = "styles-module--borderVisible--I2tEZ";
export var buttons = "styles-module--buttons--1NjF4";
export var container = "styles-module--container--zOfvO";
export var desktop = "1340px";
export var giant = "2200px";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var leftColumn = "styles-module--leftColumn--O8vnw";
export var linkTags = "styles-module--linkTags--jZeSA";
export var mobile = "400px";
export var removeTopBorder = "styles-module--removeTopBorder--QblrJ";
export var rightColumn = "styles-module--rightColumn--NlY2W";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";