import React from "react";
import { Helmet } from "react-helmet";

interface IProps {}

const Facebook = ({}: IProps) => {
  return (
    <>
      <Helmet>
        {/* <meta
          property="og:title"
          content="When Great Minds Don’t Think Alike"
        />
        <meta
          property="og:description"
          content="How much does culture influence creative thinking?"
        /> */}
        <meta property="og:image" content="/images/OTP_openGraph.png" />
      </Helmet>
    </>
  );
};

export default Facebook;
